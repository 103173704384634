import { asLink, isFilled } from "@prismicio/helpers";
import React, { useContext } from "react";
export const FeaturedProductsContext = React.createContext({
    products: [],
});
export const useFeaturedProducts = () => useContext(FeaturedProductsContext);
export const toFeaturedValidProduct = (item, product) => {
    return {
        cover: item.cover,
        url: asLink(item.product_link) || "",
        target: isFilled.link(item.product_link) && item.product_link.link_type === "Web" ? item.product_link.target : "",
        ...product,
    };
};
