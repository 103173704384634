import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { ArrowIcon } from "../components/Icon/Arrow";
import { usePrevNextButtons } from "../hooks";
const alignmentMap = {
    right: "text-right",
    center: "text-center",
    left: "text-left",
};
export const ProductsContainer = ({ backgroundColor, alignment, titleColor, descriptionColor, title, description, children, className, }) => (<div className={classNames(className, "text-gray-base")} style={backgroundColor ? { backgroundColor } : {}}>
        <div className={classNames("mb-2 pt-5 text-3xl font-bold", alignmentMap[alignment || "left"] || alignmentMap.left)} style={titleColor ? { color: titleColor } : {}}>
            <div>{title}</div>
        </div>
        <div className={classNames("mb-2 text-base", alignmentMap[alignment || "left"] || alignmentMap.left)} style={descriptionColor ? { color: descriptionColor } : {}}>
            {description}
        </div>
        {children}
    </div>);
const slideSizeMap = {
    4: "lg:basis-1/4",
    5: "lg:basis-1/5",
    6: "lg:basis-1/6",
};
export const ProductCarousel = ({ items, displayNumber, CarouselItem, Button, CarouselContainer, }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ align: "start" });
    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
    return (<div className="relative px-6 py-3 lg:px-0">
            <div ref={emblaRef} className="w-full overflow-hidden">
                <CarouselContainer className="flex w-full touch-pan-y">
                    {items.map((item, index) => (<CarouselItem key={index} item={item} className={classNames("shrink-0 grow-0 basis-[50%]", slideSizeMap[displayNumber])}/>))}
                </CarouselContainer>
            </div>
            <Button className={classNames("absolute top-[38%] left-2.5 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow disabled:hidden lg:top-[45%] lg:h-10 lg:w-10")} disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
                <ArrowIcon height={20} width={20} className="lg:scale-125"/>
            </Button>
            <Button className={classNames("absolute top-[38%] right-2.5 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow disabled:hidden lg:top-[45%] lg:h-10 lg:w-10", nextBtnDisabled ? "hidden" : "block")} disabled={nextBtnDisabled} onClick={onNextButtonClick}>
                <ArrowIcon height={20} width={20} className="translate-x-0.5 rotate-180 lg:scale-125"/>
            </Button>
        </div>);
};
