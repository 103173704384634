import { useCallback, useEffect, useState } from "react";
export const usePrevNextButtons = (emblaApi) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi)
            return;
        emblaApi.scrollPrev();
    }, [emblaApi]);
    const onNextButtonClick = useCallback(() => {
        if (!emblaApi)
            return;
        emblaApi.scrollNext();
    }, [emblaApi]);
    const onSelect = useCallback((emblaApi) => {
        setPrevBtnDisabled(!(emblaApi === null || emblaApi === void 0 ? void 0 : emblaApi.canScrollPrev()));
        setNextBtnDisabled(!(emblaApi === null || emblaApi === void 0 ? void 0 : emblaApi.canScrollNext()));
        setSelectedIndex((emblaApi === null || emblaApi === void 0 ? void 0 : emblaApi.selectedScrollSnap()) || 0);
    }, []);
    const onDotButtonClick = useCallback((index) => {
        if (!emblaApi)
            return;
        emblaApi.scrollTo(index);
        setSelectedIndex(index);
    }, [emblaApi]);
    useEffect(() => {
        if (!emblaApi)
            return;
        onSelect(emblaApi);
        emblaApi.on("reInit", onSelect);
        emblaApi.on("select", onSelect);
    }, [emblaApi, onSelect]);
    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
        selectedIndex,
        onDotButtonClick,
    };
};
