import { getGroup, getPdp, retry } from "@jog/shared";
import { useRouter } from "next/router";
import { createContext, useCallback, useContext, useEffect, useState, } from "react";
import { getItemData } from "./useProductDetail";
const PdpContext = createContext({});
export const usePdp = () => useContext(PdpContext);
export const PdpProvider = ({ children, pdp, detail, }) => {
    const [sku, setSku] = useState("");
    const [productData, setProductData] = useState(null);
    const [pending, setPending] = useState(true);
    const [variants, setVariants] = useState([]);
    const router = useRouter();
    const fetchPdpData = useCallback(async () => {
        setPending(true);
        const res = await getPdp(detail.ctpId);
        if (!res) {
            await router.push("/404");
            return;
        }
        setProductData(res);
        setPending(false);
    }, [detail.ctpId, router]);
    const fetchVariants = useCallback(async () => {
        const parent = detail.parent;
        if (!parent || parent === "-")
            return;
        await retry(() => getGroup(parent))
            .then((res) => { var _a; return ((_a = res === null || res === void 0 ? void 0 : res[parent]) === null || _a === void 0 ? void 0 : _a.map((item) => getItemData(item, pdp.variantItemCover))) || []; })
            .then((res) => setVariants(res));
    }, [detail.parent, pdp.variantItemCover]);
    useEffect(() => {
        if (router.isReady) {
            Promise.all([fetchVariants(), fetchPdpData()]).catch(() => {
                router.push("/404").catch(() => { });
            });
        }
    }, [fetchPdpData, fetchVariants, router, router.isReady]);
    const [fasletAvailable, setFasletAvailable] = useState(false);
    useEffect(() => {
        window._faslet = {
            onButtonShow: () => setFasletAvailable(true),
            onButtonHidden: () => setFasletAvailable(false),
        };
    }, [setFasletAvailable]);
    return (<PdpContext.Provider value={{ pdp, detail, sku, setSku, pending, variants, productData, fasletAvailable, setFasletAvailable }}>
            {children}
        </PdpContext.Provider>);
};
